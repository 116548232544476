@use '@angular/material' as mat;
@use 'devextreme/scss/bundles/dx.light' as dx-light;
@use 'devextreme/scss/bundles/dx.common' as dx-common;
@use "scss/colors" as clr;

.dx-widget {
	font-family: var(--font-family), sans-serif;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td {
	background-color: mat.get-color-from-palette(mat.$grey-palette, 300);
}

//.dx-state-hover {
//	background-color: mat.get-color-from-palette(clr.$gd-theme-accent, 50);
//}

/* Override the dx-state-hover background color for DevExtreme table rows */
.dx-datagrid .dx-data-row.dx-state-hover {
	background-color: mat.get-color-from-palette(clr.$gd-theme-accent, 50) !important; /* Use your desired color */
}

/* Override the dx-state-hover background color for DevExtreme table cells */
.dx-datagrid .dx-cell.dx-state-hover {
	background-color: mat.get-color-from-palette(clr.$gd-theme-accent, 50) !important; /* Use your desired color */
}

.dxList--clickable {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.dxList--link[role="gridcell"]:not(.dx-editor-cell) {
	position: relative;

	cursor: pointer;
	display: block;
	justify-content: flex-start;
	align-items: center;

	&::after {
		position: absolute;
		right: 12px;
		top: 0;
		content: '';
		background-image: url('/src/img/common/open_in_new_48dp_FILL0_wght300_GRAD0_opsz48.svg');
		width: 8px;
		height: 8px;
		background-size: 8px 8px;
		margin-left: 4px
	}
}

.dxList--h6[role="gridcell"]:not(.dx-editor-cell) {
	font-size: 0.75rem;
}

@use '@angular/material' as mat;
@use "@datachain/ui-sdk/assets/styles/components";
@use "scss/colors" as clr;

* + * {
	app-panel {
		--panel-icon-line-height: 1rem !important;
		--panel-search-height: 2rem !important;
		--panel-badge-clr: #{mat.get-color-from-palette(clr.$gd-theme-primary, 500)} !important;
	}

	app-popover:not([data-style-override]) {
		--popover-badge-clr: #{mat.get-color-from-palette(clr.$gd-theme-primary, 500)} !important;
		--popover-header-icon: #{mat.get-color-from-palette(clr.$gd-theme-primary, 500)} !important;
	}

	app-overlay-badge:not([data-style-override]) {
		--icon-clr: #{clr.$grey-dark4} !important;
		--badge-padding: 0 0.125rem !important;
		--icon-txt-spacing: 0.125rem !important;
	}

	app-modal {
		--modal-footer-padding: 1rem 1.5rem !important;
		--modal-header-padding: 0.5rem 0.5rem 0 1rem!important;
	}

	app-button {
		--adb-button-spinner-color: white !important;
	}
}

#raw-rest-json, #raw-odata-json {
	ol.json-lines >li:nth-child(odd)  { background-color: transparent; }
	ol.json-lines >li:nth-child(even) { background-color: transparent; }
}

#raw-odata-xml > pre[class*=language-].line-numbers.line-numbers code {
	border-left: none;
	box-shadow: none;
	background-image: none;
}

@use "@angular/material" as mat;
@use "./colors" as clr;

$gd-typography: mat.define-typography-config(
	$font-family: var(--font-family),
	//$display-4:     mat.define-typography-level($font-size: 96px, $font-weight: 300, $font-family: Rubik),
	//$display-3:     mat.define-typography-level($font-size: 60px, $font-weight: 500, $font-family: Rubik),
	//$display-2:     mat.define-typography-level($font-size: 48px, $font-weight: 500, $font-family: Rubik),
	//$display-1:     mat.define-typography-level($font-size: 34px, $font-weight: 500, $font-family: Rubik),
	//$headline:      mat.define-typography-level($font-size: 24px, $font-weight: 500, $font-family: Rubik),
	//$title:         mat.define-typography-level($font-size: 20px, $font-weight: 500, $font-family: Rubik),
	//$subheading-2:  mat.define-typography-level($font-size: 18px, $font-weight: 500, $font-family: Rubik),
	//$subheading-1:  mat.define-typography-level($font-size: 20px, $font-weight: 500, $font-family: Rubik),
	//$body-2:        mat.define-typography-level($font-size: 16px, $font-weight: 400, $font-family: Rubik),
	//$body-1:        mat.define-typography-level($font-size: 18px, $font-weight: 400, $font-family: Rubik),
	//$caption:       mat.define-typography-level($font-size: 16px, $font-weight: Medium, $font-family: Rubik),
	//$button:        mat.define-typography-level($font-size: 16px, $font-weight: 500, $font-family: Rubik),
	//	// Line-height must be unit-less fraction of the font-size.
	//$input:         mat.define-typography-level($font-size: inherit, $line-height: 1.125, $font-weight: 500, $font-family: Rubik),
);


$gd-theme: mat.define-light-theme((
	color:(
		primary: clr.$gd-theme-primary,
		accent: clr.$gd-theme-accent,
		warn: clr.$gd-theme-warn
	),
	typography: $gd-typography
));

// includes custom material theme
@include mat.core();
@include mat.all-component-typographies($gd-typography);
@include mat.all-component-themes($gd-theme);


.mat-checkbox-label, .mat-radio-label {
	font-weight: 400;
}
